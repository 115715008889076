<template>
  <div>
    <form-wizard
      color="#0D6EFD"
      :aria-disabled="isdisabled"
      :title="null"
      :subtitle="null"
      shape="square"
      finish-button-text="Submit"
      back-button-text="Previous"
      class="mb-3"
      @on-complete="formSubmitted"
    >
      <!-- accoint details tab -->
      <tab-content title="Account Details" :before-change="validationForm">
        <validation-observer ref="accountRules" tag="form">
          <b-row>
            <!-- start Account Details -->
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">Account Details</h5>
              <small class="text-muted"> Enter Admin Account Details. </small>
            </b-col>
            <!-- username -->
            <b-col md="6">
              <b-form-group
                v-b-popover.hover.top="'Please Enter Username'"
                label="Username"
                label-for="username"
              >
                <validation-provider
                  #default="{ errors }"
                  name="username"
                  rules="required"
                >
                  <b-form-input
                    id="name"
                    v-model="registerData.username"
                    :state="errors.length > 0 ? false : null"
                    placeholder="johndoe"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- email -->
            <b-col md="6">
              <b-form-group
                v-b-popover.hover.top="'Please Enter Valid Email'"
                label="Email"
                label-for="email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="email"
                    v-model="registerData.email"
                    type="email"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Example@MobileMasr.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- password -->
            <b-col md="6">
              <b-form-group
                v-b-popover.hover.top="'Choose a password'"
                label="Password"
                label-for="password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="Password"
                  rules="required|password"
                >
                  <b-form-input
                    id="password"
                    autocomplete="password"
                    v-model="registerData.password"
                    type="password"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Password"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- confirm password -->
            <b-col md="6">
              <b-form-group
                v-b-popover.hover.top="'Confirm Password'"
                label="Confirm Password"
                label-for="c-password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Password Confirm"
                  rules="required|confirmed:Password"
                >
                  <b-form-input
                    id="c-password"
                    autocomplete="c-password"
                    v-model="registerData.password_confirmation"
                    type="password"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Re-type Password"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>
      <!-- start Personal Info -->
      <tab-content title="Personal Info" :before-change="validationFormInfo">
        <validation-observer ref="infoRules" tag="form">
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">Personal Info</h5>
              <small class="text-muted">Enter Admin Personal Info.</small>
            </b-col>
            <!-- first name -->
            <b-col md="6">
              <b-form-group label="First Name" label-for="first-name">
                <validation-provider
                  #default="{ errors }"
                  name="First Name"
                  rules="required"
                >
                  <b-form-input
                    id="first-name"
                    v-model="registerData.firstname"
                    placeholder="John"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- lastname -->
            <b-col md="6">
              <b-form-group label="Last Name" label-for="last-name">
                <validation-provider
                  #default="{ errors }"
                  name="Last Name"
                  rules="required"
                >
                  <b-form-input
                    id="last-name"
                    v-model="registerData.lastname"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Doe"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- birthday -->
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Birthday"
                rules="required"
              >
                <b-form-group
                  label="Birthday"
                  label-for="Birthday"
                  :state="errors.length > 0 ? false : null"
                >
                  <flat-pickr
                    v-model="registerData.birthdate"
                    class="form-control"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- address -->
            <b-col md="6">
              <b-form-group label="Address" label-for="address">
                <validation-provider
                  #default="{ errors }"
                  name="Address"
                  rules="required"
                >
                  <b-form-input
                    id="address"
                    v-model="registerData.address"
                    placeholder="15 st, maddi, cairo  egypt"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- phone -->
            <b-col md="6">
              <b-form-group label="Phone" label-for="phone">
                <validation-provider
                  #default="{ errors }"
                  name="Phone"
                  rules="required"
                >
                  <cleave
                    id="phone"
                    v-model="registerData.phone"
                    class="form-control"
                    :options="options.phone"
                    placeholder="xxxxxxxx"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- gender -->
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Gender"
                rules="required"
              >
                <b-form-group
                  label="Gender"
                  label-for="gender"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="country"
                    v-model="genderSelect"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="genderOptions"
                    :selectable="
                      (option) => !option.value.includes('select_value')
                    "
                    label="text"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>
      <!-- start Identity -->
      <tab-content title="Identity" :before-change="validationFormAddress">
        <validation-observer ref="addressRules" tag="form">
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">Identity</h5>
              <small class="text-muted">Enter Admin Identity.</small>
            </b-col>
            <!-- Profile picture -->
            <b-col md="6">
              <b-form-group label="Profile picture" label-for="profile-picture">
                <validation-provider
                  #default="{ errors }"
                  name="Profile picture"
                  rules="required"
                >
                  <b-form-file
                    v-model="ProfilePictureBase64"
                    @change="uploadImage"
                    id="ProfilePictureBase64"
                    accept="image/*"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Id front picture -->
            <b-col md="6">
              <b-form-group
                label="Id front picture"
                label-for="id-front-picture"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Id front picture"
                  rules="required"
                >
                  <b-form-file
                    v-model="ProfilePictureBase642"
                    @change="uploadImage2"
                    id="ProfilePictureBase642"
                    accept="image/*"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Id back picture -->
            <b-col md="6">
              <b-form-group label="Id back picture" label-for="id-back-picture">
                <validation-provider
                  #default="{ errors }"
                  name="Id back picture"
                  rules="required"
                >
                  <b-form-file
                    v-model="ProfilePictureBase643"
                    @change="uploadImage3"
                    id="ProfilePictureBase643"
                    accept="image/*"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>
      <!-- start Roles&Permissions-->
      <tab-content
        title="Roles&Permissions"
        :before-change="validationFormSocial"
      >
        <validation-observer ref="socialRules" tag="form">
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">Social Links</h5>
              <small class="text-muted">Enter Admin Roles & Permissions</small>
            </b-col>
            <!-- Adminstration -->
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Adminstration"
                rules="required"
              >
                <b-form-group
                  label="Adminstration"
                  label-for="adminstration"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="adminstration"
                    v-model="adminstrationSelect"
                    :state="adminstrationSelect === null ? false : true"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="adminstrationOptions"
                    :selectable="
                      (option) => !option.value.includes('select_value')
                    "
                    label="text"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Role -->
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Role"
                rules="required"
              >
                <b-form-group
                  label="Role"
                  label-for="role"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    class="text-capitalize"
                    id="Role"
                    v-model="roleSelect"
                    :state="roleSelect === null ? false : true"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="roleOptions"
                    :selectable="
                      (option) => !option.value.includes('select_value')
                    "
                    label="text"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>
    </form-wizard>
    <!-- success handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showSuccessleAlert"
      @dismissed="showSuccessAlert = false"
      variant="success"
    >
      <h4 class="alert-heading">Success</h4>
      <div class="alert-body">
        <span>{{ success_message }}</span>
      </div>
    </b-alert>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul v-for="(values, index) in errors_back" :key="index">
          <li v-for="(value, valIndex) in values" :key="valIndex">
            {{ value }}
          </li>
        </ul>
      </div>
    </b-alert>
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

import { required, email } from '@validations'
import { codeIcon } from './code'
import flatPickr from 'vue-flatpickr-component'
import { codeBasic } from './TimePickerJS/code'
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'
import { codeMask } from './PhoneInputJs/code'

// import axios from '@/libs/axios'
export default {
  components: {
    FormWizard,
    TabContent,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    flatPickr,
    Cleave,
  },

  data() {
    return {
      showDismissibleAlert: false,
      showSuccessleAlert: false,
      formIsDisabled: false,
      errors_back: [],

      ProfilePictureBase64: [],
      ProfilePictureBase642: [],
      ProfilePictureBase643: [],
      registerData: {
        username: '',
        email: '',
        password: '',
        password_confirmation: '',
        firstname: '',
        lastname: '',
        birthdate: '',
        address: '',
        phone: '',
        gender: '',
        profile_picture: [],
        idfront_picture: [],
        idback_picture: [],
        adminstration: '',
        role: '',
      },
      phone: {
        phone: true,
        phoneRegionCode: 'EG',
      },
      required,
      email,
      codeIcon,
      codeBasic,
      codeMask,
      genderSelect: {
        value: '',
        text: '',
      },
      genderOptions: [
        {
          value: 'select_value',
          text: 'Select Value',
        },
        {
          value: 'male',
          text: 'Male',
        },
        {
          value: 'female',
          text: 'Female',
        },
      ],
      adminstrationSelect: {
        value: '',
        text: '',
      },
      adminstrationOptions: [
        {
          value: 'select_value',
          text: 'Select Value',
        },
        {
          value: 'manager',
          text: 'Manager',
        },
        {
          value: 'admin',
          text: 'Admin',
        },
      ],
      roleSelect: {
        value: '',
        text: '',
      },
      roleOptions: [
        {
          value: 'select_value',
          text: 'Select Value',
        },
      ],
      options: {
        phone: {
          phone: true,
          phoneRegionCode: 'EG',
        },
      },
      success_message: '',
      isdisabled: false,
    }
  },
  created() {
    // roles index
    axios
      .get('role/index')
      .then((result) => {
        const data = result.data.data
        for (let role in data) {
          this.roleOptions.push({
            value: data[role].name,
            text: data[role].name,
          })
        }
      })
      .catch((err) => {
        this.errors_back.length = 0
        if (err.response.data.data != null) {
          this.errors_back = err.response.data.data
          this.showDismissibleAlert = true
        } else {
          this.errors_back = []
          this.errors_back.push({
            error: 'internal server error',
          })
          this.showDismissibleAlert = true
        }
      })
  },
  methods: {
    formSubmitted() {
      this.isdisabled = true
      // alerts Errors & Success
      this.showSuccessleAlert = false
      this.showDismissibleAlert = false

      // select value of selections input
      this.registerData.gender = this.genderSelect.value
      this.registerData.adminstration = this.adminstrationSelect.value
      this.registerData.role = this.roleSelect.value

      // get image Data
      if (this.registerData.profile_picture.includes(',')) {
        this.registerData.profile_picture = this.registerData.profile_picture
          .split(',')
          .map((item) => item.trim())
        this.registerData.profile_picture = this.registerData.profile_picture[1]
      } else {
        this.registerData.profile_picture = this.registerData.profile_picture[1]
      }

      if (this.registerData.idfront_picture.includes(',')) {
        this.registerData.idfront_picture = this.registerData.idfront_picture
          .split(',')
          .map((item) => item.trim())
        this.registerData.idfront_picture = this.registerData.idfront_picture[1]
      } else {
        this.registerData.idfront_picture = this.registerData.idfront_picture[1]
      }

      if (this.registerData.idback_picture.includes(',')) {
        this.registerData.idback_picture = this.registerData.idback_picture
          .split(',')
          .map((item) => item.trim())
        this.registerData.idback_picture = this.registerData.idback_picture[1]
      } else {
        this.registerData.idback_picture = this.registerData.idback_picture[1]
      }

      //start request from api
      axios
        .post('registration', this.registerData)
        .then((result) => {
          this.$swal({
            position: 'center',
            icon: 'success',
            title: 'Your work has been saved',
            showConfirmButton: false,
            timer: 1500,
          })
          this.isdisabled = false
          this.formIsDisabled = false
          this.$router.push('/admin/index')
        })
        .catch((err) => {
          this.$swal({
            position: 'center',
            icon: 'error',
            title: 'Error!',
            showConfirmButton: false,
            timer: 1500,
          })

          this.isdisabled = false
          this.formIsDisabled = false

          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
    // cencode profile picture
    uploadImage(e) {
      let files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      this.createImage(files[0])
    },
    createImage(file) {
      let reader = new FileReader()
      let vm = this
      reader.onload = (e) => {
        vm.registerData.profile_picture = e.target.result
      }
      reader.readAsDataURL(file)
    },

    // encode id front pictuer
    uploadImage2(e) {
      let files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      this.createImage2(files[0])
    },
    createImage2(file) {
      let reader = new FileReader()
      let vm = this
      reader.onload = (e) => {
        vm.registerData.idfront_picture = e.target.result
      }
      reader.readAsDataURL(file)
    },

    // encode id back picture
    uploadImage3(e) {
      let files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      this.createImage3(files[0])
    },
    createImage3(file) {
      let reader = new FileReader()
      let vm = this
      reader.onload = (e) => {
        vm.registerData.idback_picture = e.target.result
      }
      reader.readAsDataURL(file)
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then((success) => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then((success) => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormAddress() {
      return new Promise((resolve, reject) => {
        this.$refs.addressRules.validate().then((success) => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormSocial() {
      return new Promise((resolve, reject) => {
        this.$refs.socialRules.validate().then((success) => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>


<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';

</style>